@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --mute: #767676;
  --cover: #f8f9fa;
  --gristopo: #565758;
}

.header {
  border-bottom: 1px solid #ccc;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #ffffff;
  background-color: var(--white);
}
/* Home page */
.home-main {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("https://images.unsplash.com/photo-1550949987-33f716ccc232?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1308&q=80");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 50px 0 50px 0;
  height: 750px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-form-control {
  width: 350px;
}

/* Sign in page */
.sign-in-main {
  background-color: #f8f9fa;
  background-color: var(--cover);
  margin-top: 49px;
  padding: 50px 0 50px 0;
}
.sign-in-container {
  padding: 15px;
  width: 500px;
  background-color: #ffffff;
  background-color: var(--white);
}
.sign-in-header {
  text-align: center;
}
.sign-in-intro {
  font-size: 14px;
}

.footer {
  border-top: 1px solid #ccc;
  width: 100%;
  padding: 10px 10px 10px 10px;
  text-align: right;
}
.footer-container {
  padding: 15px 0 15px 0;
}
.footer-item {
  padding: 10px;
}
.footer-header {
  font-size: 14px;
  font-weight: 550;
}
.footer-ul {
  list-style: none;
}
.footer-li {
  font-size: 14px;
  color: #767676;
  color: var(--mute);
}
.footer-store {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-store-logo {
  padding: 10px;
  width: 220px;
}

.home-page {
  background-color: #f8f9fa;
  background-color: var(--cover);
  padding-top: 80px;
  padding-bottom: 80px;
}

.select-wrapper + label {
  pointer-events: none;
  z-index: 1;
}

.cont {
  position: absolute;
  top: 60px;
}

li {
  text-decoration: none;
}

ul {
  list-style: none;
}

nav a:hover {
  text-decoration: none;
}

.item-menu {
  padding: 10px;
  background: grey;
  display: inline;
  margin-right: 10px;
  text-decoration: none;
  color: white !important;
}

.boton-propio {
  padding: 10px;
  background: grey;
  display: inline;
  margin-right: 10px;
  text-decoration: none;
  color: white !important;
}

/* .formulario {
  width: 400px;
} */

@media screen {
	.printing-section {
		display: none;
	}
}

.alert-danger {
  position: absolute;
  width: 100%;
  top: 0;
  justify-content: space-between;
  animation: slideDown 1s ease-in-out;
}

@keyframes slideDown {
  from {
    transform: translate3d(0, -150px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

