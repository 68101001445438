.cont {
  position: absolute;
  top: 60px;
}

li {
  text-decoration: none;
}

ul {
  list-style: none;
}

nav a:hover {
  text-decoration: none;
}

.item-menu {
  padding: 10px;
  background: grey;
  display: inline;
  margin-right: 10px;
  text-decoration: none;
  color: white !important;
}

.boton-propio {
  padding: 10px;
  background: grey;
  display: inline;
  margin-right: 10px;
  text-decoration: none;
  color: white !important;
}

/* .formulario {
  width: 400px;
} */

@media screen {
	.printing-section {
		display: none;
	}
}
